'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:UserSettings

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'UserSettings', [
    'DispatchGroups'
    'IncidentPriorities'
    '$cookies'
    '$log'
    '$q'
    (DispatchGroups, IncidentPriorities, $cookies, $log, $q) ->
      UserSettingsBase = {}

      UserSettingsBase.localKeyPrefix = '_lpa_usr.'
      UserSettingsBase.getLocal = (key, defaultValue = null) ->
        key = "#{UserSettingsBase.localKeyPrefix}#{key}"
        value = localStorage.getItem key
        value = if value? then JSON.parse(value) else defaultValue

        return value
      UserSettingsBase.setLocal = (key, value) ->
        key = "#{UserSettingsBase.localKeyPrefix}#{key}"
        value = JSON.stringify(value)
        localStorage.setItem key, value

      UserSettingsBase.getDispatchGroups = () ->
        return $q (resolve, reject) ->
          DispatchGroups.getList().then (result) ->
            resolve result
          , (error) ->
            reject error

      UserSettingsBase.getGroups = () ->
        return $q (resolve, reject) =>
          @getDispatchGroups().then (groups) ->
            resolve
              open: false
              options: groups
              values: {}
          , (error) ->
            reject error

      UserSettingsBase.getDispatchPrioCodes = () ->
        return $q (resolve, reject) ->
          IncidentPriorities.getList().then (result) ->
            resolve result
          , (error) ->
            reject error

      UserSettingsBase.getPrioCodes = () ->
        return $q (resolve, reject) =>
          @getDispatchPrioCodes().then (codes) ->
            resolve
              open: false
              options: codes
              values: {}
          , (error) ->
            reject error

      UserSettingsBase
  ]
